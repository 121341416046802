<script setup lang="ts">
import { onMounted, ref, watch, computed, nextTick, onUnmounted, shallowRef } from 'vue';
import { useElementBounding } from '@vueuse/core';
import * as d3 from 'd3';
import type { TooltipData } from '../../nrjx/chart/tooltip/index.vue';
import dayjs from 'dayjs';
import { theme } from '#tailwind-config';

export interface ChartLineOptions {
  x?: {
    ticks?: {
      callback?: (value: number) => string;
    };
  };
  tooltip?: (
    data: {
      index: number;
      label: string;
      data: number;
    }[],
  ) => TooltipData | null;
  eventTooltip?: (index: number) => TooltipData;
}

export interface ChartLineData {
  labels: Date[];
  datasets: {
    label: string;
    data: number[];
    color: string;
    dashed?: boolean;
  }[];
}

const props = defineProps<{
  title?: string;
  disabledButton?: boolean;
  disabledZoom?: boolean;
  data: ChartLineData;
  options?: ChartLineOptions;
  eventError?: string;
  eventLoading?: boolean;
  isZoomed?: boolean;
  showEventBar?: boolean;
  events?: { start: Date; end: Date; title?: string; label?: string; id: number }[];
  loading?: boolean;
  legend?: boolean;
}>();

const emit = defineEmits<{
  'period-selected': [{ start: Date; end: Date }];
  unzoom: [];
}>();

const chartId = ref(`chart-${Math.random().toString(36).substr(2, 9)}`);
const svgContainer = ref<HTMLDivElement | null>(null);
const tooltip = ref<HTMLDivElement | null>(null);
const tooltipContainer = ref<HTMLDivElement | null>(null);
const eventBar = ref<HTMLDivElement | null>(null);
const { width: eventBarWidth } = useElementBounding(eventBar);

// D3 specific refs
const svg = shallowRef<d3.Selection<SVGGElement, unknown, null, undefined>>();
const xScale = shallowRef<d3.ScaleTime<number, number>>();
const yScale = shallowRef<d3.ScaleLinear<number, number>>();
const xAxis = shallowRef<d3.Selection<SVGGElement, unknown, null, undefined>>();
const yAxis = shallowRef<d3.Selection<SVGGElement, unknown, null, undefined>>();
const clipPath = shallowRef<d3.Selection<SVGRectElement, unknown, null, undefined>>();
const yAxisWidth = ref(0);
const xAxisHeight = ref(0);

// Add tooltip container ref and position
const tooltipPosition = ref<{
  x: number;
  y: number;
  display: boolean;
  arrowPosition?: 'top' | 'right' | 'bottom' | 'left';
  isAbove?: boolean;
}>();
const tooltipData = ref<TooltipData | null>(null);

// Add event bar tooltip state
const eventTooltipPosition = ref<{
  x: number;
  y: number;
  display: boolean;
  arrowPosition?: 'top' | 'right' | 'bottom' | 'left';
  isAbove?: boolean;
  arrowLeftPosition: string;
  arrowTransform: string;
}>();
const eventTooltipData = ref<TooltipData | null>(null);

// Dimensions
const margin = computed(() => ({
  top: 20,
  right: 0,
  bottom: props.showEventBar ? xAxisHeight.value + 8 : xAxisHeight.value + 30,
  left: yAxisWidth.value + 20,
}));
const { width: containerWidth, height: containerHeight } = useElementBounding(svgContainer);
const chartWidth = computed(() => (containerWidth.value || 600) - margin.value.left - margin.value.right);
const chartHeight = computed(() => (containerHeight.value || 400) - margin.value.top - margin.value.bottom);

// Computed
const checkIfDataIsEmpty = computed(() => {
  if (!props.data?.labels?.length) return true;
  return false;
});

// Add computed for timeline elements
const calculatedTimelineElements = computed(() => {
  if (props.loading || !props.events || !props.data.labels || !containerWidth.value) return [];

  const firstDate = dayjs(props.data.labels[0]);
  const lastDate = dayjs(props.data.labels[props.data.labels.length - 1]);

  // Calculate the total minutes and graduation width
  const totalMinutes = lastDate.diff(firstDate, 'minute');
  if (totalMinutes <= 0) return [];
  const graduationWidth = eventBarWidth.value / totalMinutes;

  const eventElements = [];
  props.events.map((event, index) => {
    // Check if there is an empty space before the first event
    if (index === 0 && firstDate.isBefore(dayjs(event.start))) {
      eventElements.push({
        type: 'no_event',
        start: firstDate.toDate(),
        end: dayjs(event.start).toDate(),
        width: graduationWidth * dayjs(event.start).diff(firstDate, 'minute'),
      });
    }
    // Check if there is an empty space between events
    else if (eventElements.at(-1) && dayjs(eventElements.at(-1)?.end).isBefore(dayjs(event.start))) {
      eventElements.push({
        type: 'no_event',
        start: eventElements.at(-1)?.end,
        end: dayjs(event.start).subtract(1, 'second'),
        width: graduationWidth * dayjs(event.start).diff(eventElements.at(-1).end, 'minute'),
      });
    }

    // Add the event
    eventElements.push({
      type: 'event',
      index: event.id,
      start: event.start,
      end: event.end,
      width: graduationWidth * dayjs(event.end).diff(dayjs(event.start), 'minute'),
      event: event,
    });

    if (index === props.events.length - 1 && lastDate.isAfter(dayjs(event.end))) {
      eventElements.push({
        type: 'no_event',
        start: dayjs(event.end).toDate(),
        end: lastDate.toDate(),
        width: graduationWidth * dayjs(lastDate).diff(dayjs(event.end), 'minute'),
      });
    }
  });

  return eventElements;
});

// Calculate Y axis width
const calculateYAxisWidth = () => {
  if (!svgContainer.value || !props.data.labels.length) return;

  // Create temporary container
  const tempSvg = d3.select(svgContainer.value).append('svg').style('visibility', 'hidden');

  // Create temporary scale
  const tempYScale = d3
    .scaleLinear()
    .domain([0, d3.max(props.data.datasets.flatMap((dataset) => dataset.data as number[])) || 0])
    .range([400, 0])
    .nice();

  // Create temporary axis
  const tempAxis = tempSvg.append('g').call(d3.axisLeft(tempYScale).tickPadding(8));

  // Get the width
  const width = (tempAxis.node() as SVGGElement)?.getBBox().width || 0;
  yAxisWidth.value = Math.ceil(width);

  // Remove temporary elements
  tempSvg.remove();
};

// Initialize scales and axes
const initScales = () => {
  if (!props.data.labels.length) return;

  const xDomain = d3.extent(props.data.labels) as [Date, Date];
  xScale.value = d3.scaleTime().domain(xDomain).range([0, chartWidth.value]);

  const allValues = props.data.datasets.flatMap((dataset) => dataset.data as number[]);
  const maxY = d3.max(allValues) || 0;
  const grace = maxY * 0.1; // 10% grace

  // Si maxY est 0, on force une échelle de 0 à 100, sinon on garde la logique normale avec la marge de 10%
  const yDomain = maxY === 0 ? [0, 100] : [0, maxY + grace];
  yScale.value = d3.scaleLinear().domain(yDomain).range([chartHeight.value, 0]).nice();
};

// Create or update the chart
const updateChart = () => {
  if (!svgContainer.value || !props.data.labels?.length) return;

  // Clear previous content
  d3.select(svgContainer.value).selectAll('*').remove();

  // Calculate Y axis width first
  calculateYAxisWidth();

  // Create temporary SVG to calculate x-axis height
  const tempSvg = d3.select(svgContainer.value).append('svg').style('visibility', 'hidden');

  // Initialize scales for temporary calculation
  const xDomain = d3.extent(props.data.labels) as [Date, Date];
  const tempXScale = d3.scaleTime().domain(xDomain).range([0, chartWidth.value]);

  // Calculate optimal number of ticks based on minimum spacing
  const minTickSpacing = 45;
  const maxTicks = Math.floor(chartWidth.value / minTickSpacing);
  const tickInterval = Math.ceil(props.data.labels.length / maxTicks);

  // Create temporary x-axis to calculate height
  const tempXAxis = tempSvg.append('g').call(
    d3
      .axisBottom(tempXScale)
      .tickPadding(props.showEventBar ? 50 : 16)
      .tickSize(0)
      .tickValues(props.data.labels.filter((_, i) => i % tickInterval === 0))
      .tickFormat((d: Date) => {
        if (props.options?.x?.ticks?.callback) {
          const index = props.data.labels.findIndex((date) => date.getTime() === d.getTime());
          if (index !== -1) {
            return props.options.x.ticks.callback(index);
          }
        }
        return d.toLocaleDateString();
      }),
  );

  // Style x-axis labels with rotation
  const tempTextSelection = tempXAxis.selectAll('text');
  const firstTempText = tempTextSelection.nodes()[0] as SVGTextElement;
  const tempTextWidth = firstTempText?.getBBox().width || 0;
  const tempHalfWidth = tempTextWidth / 2;

  tempTextSelection
    .attr('transform', `translate(${tempHalfWidth}, 0) rotate(-45)`)
    .style('text-anchor', 'end')
    .attr('dx', '0.8em')
    .attr('dy', '0.8em');

  // Calculate x-axis height
  const tempHeight = (tempXAxis.node() as SVGGElement)?.getBBox().height || 0;
  xAxisHeight.value = Math.ceil(tempHeight);

  // Remove temporary SVG
  tempSvg.remove();

  // Now create the actual SVG with correct dimensions
  svg.value = d3
    .select(svgContainer.value)
    .append('svg')
    .attr('width', chartWidth.value + margin.value.left + margin.value.right)
    .attr('height', chartHeight.value + margin.value.top + margin.value.bottom)
    .append('g')
    .attr('transform', `translate(${margin.value.left},${margin.value.top})`);

  // Initialize scales
  initScales();

  // Create clip path
  clipPath.value = svg.value
    .append('defs')
    .append('clipPath')
    .attr('id', `clip-${chartId.value}`)
    .append('rect')
    .attr('width', chartWidth.value)
    .attr('height', chartHeight.value);

  // Create x-axis
  xAxis.value = svg.value
    .append('g')
    .attr('class', 'x-axis')
    .attr('transform', `translate(0,${chartHeight.value})`)
    .call(
      d3
        .axisBottom(xScale.value!)
        .tickPadding(props.showEventBar ? 50 : 24)
        .tickSize(0)
        .tickValues(props.data.labels.filter((_, i) => i % tickInterval === 0))
        .tickFormat((d: Date) => {
          if (props.options?.x?.ticks?.callback) {
            const index = props.data.labels.findIndex((date) => date.getTime() === d.getTime());
            if (index !== -1) {
              return props.options.x.ticks.callback(index);
            }
          }
          return d.toLocaleDateString();
        }),
    );

  // Style x-axis labels with rotation
  const xAxisTextSelection = xAxis.value.selectAll('text');
  const firstAxisText = xAxisTextSelection.nodes()[0] as SVGTextElement;
  const axisTextWidth = firstAxisText?.getBBox().width || 0;
  const axisHalfWidth = axisTextWidth / 2;

  xAxisTextSelection
    .attr('transform', `translate(-${axisHalfWidth}, 0) rotate(-45)`)
    .style('text-anchor', 'end')
    .attr('dx', '0.8em')
    .attr('dy', '0.8em');

  // Create y-axis
  yAxis.value = svg.value.append('g').attr('class', 'y-axis').call(d3.axisLeft(yScale.value!).tickPadding(10).tickSize(-chartWidth.value));

  // Style axes and grid lines
  svg.value
    .selectAll('.y-axis line')
    .attr('stroke', '#e5e7eb')
    .attr('stroke-width', 1)
    .attr('stroke-dasharray', 'none')
    .attr('opacity', 0.5);

  svg.value.selectAll('.y-axis path').attr('stroke', 'none');

  // Align all y-axis text to the right
  svg.value.selectAll('.y-axis text').style('text-anchor', 'end').attr('dx', '-10');

  // Replace last tick text with title if available
  if (props.title) {
    const ticks = yAxis.value.selectAll('.tick').nodes();
    const lastTick = d3.select(ticks[ticks.length - 1]);
    if (lastTick) {
      lastTick
        .select('text')
        .text(props.title)
        .attr('fill', '#9ca3af')
        .attr('font-size', '12px')
        .style('text-anchor', 'end')
        .attr('dx', '-10');
    }
  }

  // Add lines
  const line = d3
    .line<any>()
    .x((d) => xScale.value!(d.x))
    .y((d) => yScale.value!(d.y));

  const chartGroup = svg.value.append('g').attr('clip-path', `url(#clip-${chartId.value})`);

  props.data.datasets.forEach((dataset) => {
    const lineData = dataset.data.map((y: number, j: number) => ({
      x: props.data.labels[j],
      y,
    }));

    chartGroup
      .append('path')
      .datum(lineData)
      .attr('class', 'line')
      .attr('fill', 'none')
      .attr('stroke', dataset.color as string)
      .attr('stroke-width', 1.5)
      .attr('stroke-dasharray', dataset.dashed ? '4,4' : 'none')
      .attr('d', line);
  });

  // Add tooltip events
  addTooltipEvents(chartGroup);
};

// Add mouse events to the chart
const addTooltipEvents = (_chartGroup: d3.Selection<SVGGElement, unknown, null, undefined>) => {
  const bisect = d3.bisector((d: Date) => d).left;

  // Add overlay for tooltip and drag
  const overlay = svg
    .value!.append('rect')
    .attr('class', 'overlay')
    .attr('width', chartWidth.value)
    .attr('height', chartHeight.value)
    .attr('fill', 'none')
    .attr('pointer-events', 'all')
    .on('mousedown', (event: MouseEvent) => {
      if (props.disabledZoom) return;
      const mouseX = d3.pointer(event)[0];
      dragStart.value = mouseX;
      isDragging.value = true;
      hideTooltip();

      // Add drag selection rectangle
      svg
        .value!.append('rect')
        .attr('class', 'drag-selection')
        .attr('x', mouseX)
        .attr('y', 0)
        .attr('height', chartHeight.value)
        .attr('width', 0)
        .attr('fill', 'rgba(0, 0, 0, 0.1)')
        .style('pointer-events', 'none');
    });

  // Gérer les événements sur l'overlay
  overlay
    .on('mousemove', (event: MouseEvent) => {
      const mouseX = d3.pointer(event)[0];

      if (isDragging.value && dragStart.value !== null && !props.disabledZoom) {
        dragEnd.value = mouseX;

        // Update drag selection rectangle
        const selectionWidth = dragEnd.value - dragStart.value;

        // Si on essaie de sélectionner de droite à gauche, on ne fait rien
        if (selectionWidth < 0) {
          svg.value!.select('.drag-selection').attr('width', 0);
          return;
        }

        svg.value!.select('.drag-selection').attr('x', dragStart.value).attr('width', selectionWidth);

        // Remove existing date indicators
        svg.value!.selectAll('.date-indicator-group').remove();
      } else if (!isDragging.value) {
        // Normal tooltip behavior when not dragging
        const x0 = xScale.value!.invert(mouseX);
        const i = bisect(props.data.labels, x0, 1);

        // Ajout de la logique pour gérer le premier point
        let index = i;
        if (i > 0) {
          // Compare les distances entre le point actuel et le point précédent
          const x1 = props.data.labels[i];
          const x2 = props.data.labels[i - 1];
          if (Math.abs(x0.getTime() - x2.getTime()) < Math.abs(x0.getTime() - x1.getTime())) {
            index = i - 1;
          }
        }
        showTooltip(event, index, mouseX);
      }
    })
    .on('mouseup', () => {
      // Clear selection visuals first
      svg.value!.select('.drag-selection').remove();
      svg.value!.selectAll('.date-indicator-group').remove();
      svg.value!.select('.vertical-line').remove();

      // Only emit if we have a valid selection (de gauche à droite uniquement) and zoom is not disabled
      if (
        isDragging.value &&
        dragStart.value !== null &&
        dragEnd.value !== null &&
        dragEnd.value > dragStart.value &&
        !props.disabledZoom
      ) {
        const startDate = xScale.value!.invert(dragStart.value);
        const endDate = xScale.value!.invert(dragEnd.value);

        // Emit the selected period
        emit('period-selected', { start: startDate, end: endDate });
      }

      // Reset drag state
      isDragging.value = false;
      dragStart.value = null;
      dragEnd.value = null;
      hideTooltip();
    })
    .on('mouseleave', () => {
      if (!isDragging.value) {
        hideTooltip();
      }
    });
};

// Create tooltip function
const showTooltip = (event: MouseEvent, index: number, mouseX: number) => {
  if (!props.options?.tooltip || !svgContainer.value || !tooltipContainer.value) return;

  const data = props.data.datasets.map((dataset) => ({
    index: index,
    label: dataset.label,
    data: dataset.data[index],
  }));

  // Get tooltip data from callback
  tooltipData.value = props.options?.tooltip(data);

  // Remove existing point indicator and vertical line if any
  svg.value!.select('.point-indicator-group').remove();
  svg.value!.select('.vertical-line').remove();

  // Add vertical line that follows the mouse exactly
  svg
    .value!.append('line')
    .attr('class', 'vertical-line')
    .attr('x1', mouseX)
    .attr('y1', 0)
    .attr('x2', mouseX)
    .attr('y2', chartHeight.value)
    .attr('stroke', theme.colors.gray[400])
    .attr('stroke-width', 1)
    .attr('stroke-dasharray', '3,3')
    .style('pointer-events', 'none');

  // Get container bounds and SVG position relative to viewport
  const svgRect = svgContainer.value.getBoundingClientRect();

  // Fixed vertical position - 0px from the top of the chart
  const fixedY = svgRect.top + margin.value.top;

  // Get the position of the tooltip container relative to viewport
  const tooltipContainerRect = tooltipContainer.value.getBoundingClientRect();

  // Calculer la position relative au conteneur du tooltip
  const relativeY = fixedY - tooltipContainerRect.top;

  // Déterminer si on est dans la moitié gauche ou droite du graphique
  const isLeftHalf = mouseX < chartWidth.value / 2;

  // Initialiser la position du tooltip avec une position approximative
  // Cela permet d'afficher le tooltip immédiatement
  let initialPosX = isLeftHalf ? mouseX + margin.value.left + 10 : mouseX + margin.value.left - 200 - 10; // 200 est une largeur approximative

  // Vérifier que la position initiale reste dans les limites du graphique
  if (initialPosX < margin.value.left) {
    initialPosX = margin.value.left;
  } else if (initialPosX + 200 > margin.value.left + chartWidth.value) {
    initialPosX = margin.value.left + chartWidth.value - 200;
  }

  // Mettre à jour la position du tooltip pour afficher les données immédiatement
  tooltipPosition.value = {
    x: initialPosX + svgRect.left - tooltipContainerRect.left,
    y: relativeY,
    display: true,
    arrowPosition: isLeftHalf ? 'left' : 'right',
    isAbove: false,
  };

  // Attendre que le tooltip soit rendu pour obtenir sa largeur réelle
  nextTick(() => {
    if (!tooltip.value) return;

    // Obtenir la largeur réelle du tooltip
    const tooltipElement = tooltip.value as unknown as { $el: HTMLElement };
    const tooltipWidth = tooltipElement.$el.offsetWidth;

    // Calculer la position horizontale du tooltip
    let posX;

    if (isLeftHalf) {
      // Dans la partie gauche, afficher le tooltip à droite de la ligne
      posX = mouseX + margin.value.left + 10; // 10px d'espace entre la ligne et le tooltip
    } else {
      // Dans la partie droite, afficher le tooltip à gauche de la ligne
      posX = mouseX + margin.value.left - tooltipWidth - 10; // 10px d'espace entre la ligne et le tooltip
    }

    // Vérifier que le tooltip reste dans les limites du graphique
    if (posX < margin.value.left) {
      posX = margin.value.left;
    } else if (posX + tooltipWidth > margin.value.left + chartWidth.value) {
      posX = margin.value.left + chartWidth.value - tooltipWidth;
    }

    // Mettre à jour la position avec la largeur réelle
    tooltipPosition.value = {
      x: posX + svgRect.left - tooltipContainerRect.left,
      y: relativeY,
      display: true,
      arrowPosition: isLeftHalf ? 'left' : 'right',
      isAbove: false,
    };
  });
};

const hideTooltip = () => {
  tooltipPosition.value = {
    x: 0,
    y: 0,
    display: false,
  };
  tooltipData.value = null;

  // Remove vertical line when hiding tooltip
  if (svg.value) {
    svg.value.select('.vertical-line').remove();
  }
};

// Add drag selection refs
const dragStart = ref<number | null>(null);
const dragEnd = ref<number | null>(null);
const isDragging = ref(false);

// Update the eventBarBottomPosition computed
const eventBarBottomPosition = computed(() => {
  if (!props.showEventBar) return '0px';
  const xAxisPadding = 32; // padding when event bar is shown
  const eventBarHeight = 25;
  return `${xAxisHeight.value + xAxisPadding - eventBarHeight}px`;
});

// Add hide event tooltip function
const hideEventTooltip = () => {
  eventTooltipPosition.value = {
    x: 0,
    y: 0,
    display: false,
    arrowLeftPosition: '50%',
    arrowTransform: 'translateX(-50%)',
  };
  eventTooltipData.value = null;
};

// Add show event tooltip function
const showEventTooltip = (index: number) => {
  if (!props.options?.eventTooltip || !tooltipContainer.value) return;

  // Get tooltip data from callback with index
  eventTooltipData.value = props.options.eventTooltip(index);

  const tooltipWidth = 178; // Approximate width of tooltip
  const arrowOffset = 8; // Width of the arrow

  // Get element position and dimensions
  const elementRect = (event.target as HTMLElement).getBoundingClientRect();
  const svgContainerRect = svgContainer.value!.getBoundingClientRect();

  // Calculate center of the element relative to the SVG container
  const elementCenterX = elementRect.left - svgContainerRect.left + elementRect.width / 2;
  const elementTopY = elementRect.top - svgContainerRect.top;

  // Calculate initial position (centered above the element)
  let posX = elementCenterX - tooltipWidth / 2;
  const posY = elementTopY - 10; // Small gap between tooltip and element

  // Calculate the bounds of the chart
  const leftBound = margin.value.left;
  // Utiliser la largeur totale du conteneur au lieu de chartWidth
  const rightBound = svgContainerRect.width - margin.value.right;

  // Calculate how much the tooltip would overflow on each side
  const leftOverflow = Math.max(0, leftBound - posX);
  const rightOverflow = Math.max(0, posX + tooltipWidth - rightBound);

  // Calculate the arrow position relative to the tooltip
  let arrowLeftPosition = '50%';
  const arrowTransform = 'translateX(-50%)';

  // Adjust position and arrow if tooltip would overflow
  if (leftOverflow > 0) {
    // Tooltip would overflow on the left
    posX = leftBound;
    // Calculate arrow position relative to the left edge
    const arrowPos = ((elementCenterX - leftBound) / tooltipWidth) * 100;
    arrowLeftPosition = `${Math.max(arrowOffset, Math.min(100 - arrowOffset, arrowPos))}%`;
  } else if (rightOverflow > 0) {
    // Tooltip would overflow on the right
    posX = rightBound - tooltipWidth;
    // Calculate arrow position relative to the right edge
    const arrowPos = ((elementCenterX - (rightBound - tooltipWidth)) / tooltipWidth) * 100;
    arrowLeftPosition = `${Math.max(arrowOffset, Math.min(100 - arrowOffset, arrowPos))}%`;
  }

  eventTooltipPosition.value = {
    x: posX,
    y: posY,
    display: true,
    arrowPosition: 'bottom',
    isAbove: true,
    arrowLeftPosition,
    arrowTransform,
  };
};

// Watchers and lifecycle hooks
watch(
  () => props.data,
  () => {
    if (!props.loading) {
      nextTick(() => {
        updateChart();
      });
    }
  },
  { deep: true },
);

watch(
  () => [containerWidth.value, containerHeight.value],
  () => {
    nextTick(() => {
      updateChart();
    });
  },
);

onMounted(() => {
  if (!props.loading) {
    nextTick(() => {
      updateChart();
    });
  }
});

onUnmounted(() => {
  if (svgContainer.value) {
    d3.select(svgContainer.value).selectAll('*').remove();
  }
  hideTooltip();
});
</script>

<template>
  <div class="relative">
    <div v-if="loading" class="absolute z-10 flex flex-col items-center rounded justify-center bg-gray-100 top-0 left-0 right-0 bottom-0">
      <slot name="loading">
        <ui-loader />
        <p class="text-gray-500 text-sm mt-2">{{ $t('global.loading_data') }}</p>
      </slot>
    </div>

    <div
      v-else-if="checkIfDataIsEmpty"
      class="absolute z-10 flex flex-col items-center rounded justify-center bg-opacity-50 bg-gray-100 top-[36px] left-0 right-0 bottom-0"
    >
      <slot name="no-data">
        <p class="text-gray-500 text-sm">{{ $t('global.no_data') }}</p>
      </slot>
    </div>

    <div v-if="isZoomed" class="w-full text-gray-400 pl-2 text-xs mb-2 flex items-center justify-end h-[26px]">
      <ui-button v-if="isZoomed" color="secondary" size="xs" left-icon="Minimize2" @click="emit('unzoom')">
        {{ $t('global.go_back_to_reference_period') }}
      </ui-button>
    </div>

    <div class="relative flex flex-col justify-end w-full chart-container">
      <div ref="svgContainer" class="w-[100%] h-[50vh]" :class="{ 'mb-6': showEventBar }" />

      <!-- Graph tooltip -->
      <div ref="tooltipContainer" class="absolute transition-transform top-0 left-0 pointer-events-none z-[9999] w-full h-full">
        <nrjx-chart-tooltip
          v-if="tooltipData && tooltipPosition?.display"
          ref="tooltip"
          v-bind="tooltipData"
          :is-below="!tooltipPosition.isAbove"
          :no-arrow="false"
          :arrow-position="tooltipPosition.arrowPosition"
          class="absolute z-[9999] no-margin"
          :class="{
            'translate-y-0': !tooltipPosition.isAbove,
          }"
          :style="{
            left: tooltipPosition.x + 'px',
            top: tooltipPosition.y + 'px',
            margin: '0',
            padding: '0',
          }"
        />
      </div>

      <!-- Event bar tooltip -->
      <nrjx-chart-tooltip
        v-if="eventTooltipData && eventTooltipPosition?.display"
        v-bind="eventTooltipData"
        :is-below="!eventTooltipPosition.isAbove"
        :arrow-position="eventTooltipPosition.arrowPosition"
        class="absolute z-[9999]"
        :class="{
          '-translate-y-full': eventTooltipPosition.isAbove,
          'translate-y-2': !eventTooltipPosition.isAbove,
        }"
        :style="{
          left: eventTooltipPosition.x + 'px',
          top: eventTooltipPosition.y + 'px',
          '--triangle-left': eventTooltipPosition.arrowLeftPosition,
          '--triangle-transform': eventTooltipPosition.arrowTransform,
        }"
      />

      <!-- Event bar -->
      <div
        v-if="showEventBar"
        ref="eventBar"
        class="h-[24px] absolute z-0"
        :style="{
          bottom: eventBarBottomPosition,
          left: `${margin.left}px`,
          right: `${margin.right}px`,
        }"
      >
        <div v-if="eventLoading" class="flex h-full w-full">
          <ui-skeleton-loader class="h-full w-full opacity-50 rounded-b" />
        </div>
        <div v-else-if="eventError" class="flex h-full w-full">
          <div class="flex items-center justify-center rounded-b h-[24px] border border-red-200 bg-white w-full">
            <p class="text-red-500 text-xs">{{ eventError }}</p>
          </div>
        </div>
        <div v-else class="flex h-full w-full">
          <div
            v-for="(element, index) in calculatedTimelineElements"
            :key="element.type === 'event' ? element.event.title + index : `no-event-${element.width} + ${index}`"
            :style="{ width: `${element.width}px` }"
            :class="{
              'pr-[1px]': index === 0,
              'pl-[1px]': index === calculatedTimelineElements.length - 1,
              'px-[1px]': index !== 0 && index !== calculatedTimelineElements.length - 1,
            }"
          >
            <div
              :class="[
                'h-full flex items-center justify-start rounded-b event-bar-element',
                eventError ? '!border-red-200 bg-white !border-t-[1px] flex items-center justify-center' : 'border-gray-200 border-t-0',
                element.type === 'event'
                  ? 'bg-green-100 border border-green-200 text-green-500 hover:bg-green-200'
                  : 'bg-[repeating-linear-gradient(45deg,transparent,transparent_4px,#f3f4f6_4px,#f3f4f6_6px)] border border-gray-200',
              ]"
              @mouseenter="element.type === 'event' ? showEventTooltip(element.index) : null"
              @mouseleave="element.type === 'event' ? hideEventTooltip() : null"
            >
              <span
                v-if="element.type === 'event' && element.width > 50"
                class="text-xs whitespace-nowrap overflow-hidden text-ellipsis px-2"
              >
                {{ element.event.title ?? '' }} {{ element.event.label ?? '' }}
              </span>
              <span v-if="eventError" class="text-xs whitespace-nowrap overflow-hidden text-ellipsis px-2 text-red-500">
                {{ eventError }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.chart-container {
  overflow: visible;
}

:deep(.line) {
  fill: none;
  stroke-width: 1.5;
}

:deep(.x-axis),
:deep(.y-axis) {
  color: #9ca3af;
}

:deep(.x-axis line),
:deep(.y-axis line) {
  stroke: #e5e7eb;
  stroke-width: 1;
  stroke-dasharray: none;
  opacity: 0.5;
}

:deep(.x-axis .domain),
:deep(.y-axis .domain) {
  display: none;
}

:deep(.x-axis text),
:deep(.y-axis text) {
  font-size: 12px;
}

.overlay {
  cursor: crosshair;
}

:deep(.nrjx-chart-tooltip) {
  transform-origin: center var(--triangle-position, bottom);
}

/* Hide the tooltip arrow */
:deep(.no-arrow::after),
:deep(.no-arrow::before) {
  display: none !important;
}

/* Remove margins and padding from tooltip */
:deep(.no-margin) {
  margin: 0 !important;
  padding: 0 !important;
}

/* Target the inner content of the tooltip to remove its margins */
:deep(.no-margin > *) {
  margin: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.drag-selection {
  pointer-events: none;
  stroke: #666;
  stroke-width: 1px;
}
</style>
